import React from "react";
import "./Logo.css";
export default function Logo({ handleViewResume }) {
    const handleReload = () => {
        document.location.hash = "";
        window.location.reload();
    };
    return (
        <div id="logo" onClick={handleReload}>
            <svg
                className="logo_k"
                viewBox="0 0 860 800"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M11.3416 2.54485C8.14368 4.13876 5.1658 6.63261 3.37287 9.22046L0.509975 13.3512L0.25498 398.394L0 783.437L2.5149 788.579C4.12384 791.866 6.52775 794.757 9.18465 796.598L13.3385 799.477L183.384 799.738L353.429 800L358.584 797.478C361.885 795.863 364.774 793.462 366.617 790.803L369.496 786.65L369.996 639.072L370.496 491.495L372.752 487.273C376.157 480.9 380.751 476.163 386.405 473.196C390.76 470.91 392.722 470.524 399.995 470.522C406.884 470.52 409.294 470.945 412.716 472.764C419.066 476.139 423.818 480.738 426.808 486.405L429.494 491.495L429.994 638.486L430.494 785.478L432.919 789.714C434.254 792.043 437.14 795.193 439.334 796.713L443.322 799.477L643.369 799.737L843.418 799.996L848.568 797.476C851.866 795.863 854.755 793.461 856.598 790.803L859.477 786.649L859.738 616.607L860 446.564L857.478 441.41C855.863 438.109 853.462 435.22 850.802 433.377L846.65 430.498L719.068 429.998L591.487 429.498L587.266 427.242C580.893 423.838 576.155 419.243 573.188 413.589C570.902 409.234 570.516 407.273 570.514 400C570.512 393.11 570.937 390.701 572.756 387.279C576.131 380.929 580.731 376.177 586.398 373.188L591.487 370.502L688.484 370.002L785.48 369.502L789.716 367.076C792.046 365.741 795.196 362.855 796.715 360.661L799.479 356.674L799.74 186.631L800.002 16.589L797.48 11.4343C795.865 8.13353 793.465 5.24469 790.805 3.4018L786.651 0.522965L616.605 0.261978L446.56 0L441.405 2.52184C438.104 4.13675 435.215 6.53762 433.372 9.19747L430.494 13.3502L429.994 160.928L429.494 308.505L427.238 312.727C423.833 319.1 419.238 323.837 413.584 326.804C409.229 329.09 407.267 329.476 399.995 329.478C393.105 329.48 390.695 329.055 387.273 327.236C380.923 323.861 376.172 319.262 373.182 313.595L370.496 308.505L369.996 161.514L369.496 14.5222L367.07 10.2864C365.735 7.95654 362.849 4.80673 360.655 3.28681L356.667 0.522965L186.558 0.261978L16.4474 0L11.3416 2.54485Z" />
            </svg>
            <svg
                className="logo_m"
                viewBox="0 0 866 800"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M12.8613 0.977577C8.11925 2.49814 2.14526 8.74357 0.858263 13.5265C0.125263 16.2533 -0.122746 69.9686 0.055254 187.509L0.314257 357.545L2.40326 361.549C3.55226 363.751 6.37126 366.882 8.66826 368.506C12.6923 371.352 13.3643 371.481 27.1463 372.052C40.0203 372.587 42.0043 372.918 47.0223 375.376C53.5393 378.568 60.0993 385.29 63.1533 391.904L65.3143 396.585L66.3143 785.986L68.4033 789.99C69.5523 792.192 72.4033 795.345 74.7393 796.997L78.9863 800H180.15C280.808 800 281.334 799.99 285.314 797.909C287.514 796.759 290.664 793.905 292.314 791.566L295.314 787.315L296.314 292.478L299.104 286.79C303.938 276.937 312.489 271.188 323.653 270.286C335.59 269.322 346.335 275.248 352.346 286.112L355.314 291.477L356.314 785.986L358.403 789.99C359.552 792.192 362.403 795.345 364.739 796.997L368.986 800H465.15C560.737 800 561.338 799.987 565.314 797.909C567.514 796.759 570.664 793.905 572.314 791.566L575.314 787.315L576.314 292.478L579.104 286.79C583.938 276.937 592.489 271.188 603.653 270.286C615.59 269.322 626.335 275.248 632.346 286.112L635.314 291.477L636.314 785.986L638.403 789.99C639.552 792.192 642.403 795.345 644.739 796.997L648.986 800H750.15C850.808 800 851.334 799.99 855.314 797.909C857.514 796.759 860.664 793.905 862.314 791.566L865.314 787.315V14.1912L863.225 10.1871C862.076 7.98479 859.225 4.83154 856.889 3.17984L852.642 0.176749H510.314L506.314 2.26791C504.114 3.41809 500.964 6.27103 499.314 8.60843L496.314 12.8588L495.314 107.287L492.524 112.975C487.69 122.828 479.139 128.577 467.975 129.479C452.594 130.721 438.516 119.639 436.288 104.534C435.902 101.918 435.202 99.7793 434.731 99.7793C434.261 99.7793 433.749 80.5214 433.595 56.9852C433.325 15.8178 433.235 14.039 431.225 10.1871C430.076 7.98479 427.225 4.83154 424.889 3.17984L420.642 0.176749L218.478 0.0235918C73.1803 -0.0865216 15.3433 0.181758 12.8613 0.977577Z" />
            </svg>
            <svg
                className="logo_dot"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="20" cy="83" r="14" />
            </svg>
        </div>
    );
}
